.notification-item {
  all: unset;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 15px 10px 15px;
  transition: background-color 0.3s ease;

  .elapsed-time {
    color: var(--mds_brand_appearance_neutral_weakest_text-color);
  }
}

.notification-item:hover {
  background-color: var(--mds_brand_appearance_neutral_weak_background-color);
}
