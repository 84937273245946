@import "@maersk-global/fonts/maeu/fonts.css";
@import "@maersk-global/mds-foundations/css/foundations.min.css";

th {
  z-index: 0 !important;
}

.noTableHeader-header {
  display: none !important;
}

.noTableHeader-cell {
  background-color: #f7f7f7 !important;
}

.noHeader-header {
  display: none !important;
}

nav.tabs > div > ul > li:hover {
  border-bottom: none !important;
}

nav.tabs > div > ul {
  overflow: auto !important;
}

nav.tabs > div > ul > li > button > span:hover {
  border-bottom: none !important;
}

.delete-modal > div > div {
  overflow-y: hidden !important;
}

.editableTableProcessImpact-cell.name {
  text-wrap: balance !important;
}

.se-submenu.se-list-layer.se-list-font-family,
.se-submenu.se-list-layer.se-list-format {
  height: 250px !important;
}

.mc-toast-container {
  z-index: 9999 !important;
}

.item-title.dark.collapsed.mds-text--small-normal {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.menu-list-item .menu-item-content .content-wrapper.dark mc-icon::part(icon) {
  transform: scale(1.3) !important;
}

.menu-item-content.dark.inactive.collapsed:hover {
  background-color: #b5e0f5 !important;
}

.menu-list-item .menu-item-content .content-wrapper.collapsed span {
  font-size: xx-small;
}

.editableTableProcessImpact-cell {
  text-overflow: initial !important;
}

.btn-no-wrap {
  white-space: nowrap;
}
.no-scroll {
  overflow: hidden;
}

.sidebar-menu-wrapper {
  margin-top: 0px !important;
}

.tabs .tabs_ul .tabs_li.active {
  border-bottom: 2px solid
    var(--mds_brand_appearance_primary_default_border-color) !important;
}

.site-heading {
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  gap: 5px;
  color: var(--mds_brand_appearance_neutral_default_text-color);
}
