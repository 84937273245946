/* comments.scss */

.comment-reply-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--mds_brand_appearance_neutral_default_background-color);
  padding: 10px;
}

.popover-content {
  max-height: 50vh;
  overflow-y: auto;
}

.header-wrapper {
  padding: 20px 0 10px 20px;
}

.no-comments-wrapper {
  height: 200px;
  padding: 0px 20px 0px 20px;
}

.comments-wrapper {
  overflow: scroll;
  padding: 0px 20px 20px 20px;
  margin-bottom: 60px;
}

.comment-container {
  padding-top: 5px;
  padding-bottom: 5px;
}

.comment-text {
  background-color: var(
    --mds_brand_appearance_neutral_weakest_background-color
  );
  padding: 10px;
  max-width: 100%;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid var(--mds_brand_appearance_neutral_weak_border-color);
}

.comment-box {
  padding: 3px;
  display: flex;
}

.comment-updated-by {
  font-size: 16px;
  font-weight: 700;
}

.comment-status {
  color: var(--mds_brand_appearance_neutral_default_text-color);
  font-size: 12px;
  margin-left: 5px;
}

.comment-actions {
  align-items: flex-end;
  display: flex;
  margin-left: 5px;
  float: right;
}

.editing-container {
  display: flex;
  gap: 3px;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.reply-button {
  all: unset;
  font-size: 12px !important;
  color: var(--mds_brand_appearance_primary_default_text-color);
  font-weight: 700;
  cursor: pointer;
}

.comment-replies {
  margin-left: 50px;
}

.flex-grow {
  flex-grow: 1;
}

.comments-reply-wrapper {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: end;
}

.reply-textarea {
  flex-grow: 1;
  padding-left: 10px;
  padding-top: 2px;
}

.tagged-persona {
  -webkit-box-align: center;
  align-items: center;
  display: inline-flex;
  width: auto;
  font-weight: bold;
  text-decoration: underline;
}
